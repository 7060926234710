<template>
	<!-- class="bg-secondary" -->
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		ok-variant="success"
		size="lg"
		:ok-title="FormMSG(29801, 'Save')"
		:cancel-title="FormMSG(212302, 'Cancel')"
		:title="FormMSG(26700, 'Picture capture')"
		@ok="handleSaveCrop"
		@cancel="handleCloseModal"
		@hidden="handleCloseModal"
		modal-class="mui-animation"
		:fade="false"
	>
		<div>
			<div class="image_crop_container_wrapper">
				<b-row>
					<b-col>
						<vue-cropper
							ref="croppieRef"
							class="cropper-container"
							:key="refCrop"
							:img="options.img"
							:output-size="options.size"
							:output-type="options.outputType"
							:info="options.info"
							:full="options.full"
							:fixed="options.fixed"
							:fixed-number="options.fixedNumber"
							:can-move="options.canMove"
							:can-move-box="options.canMoveBox"
							:fixed-box="options.fixedBox"
							:original="options.original"
							:auto-crop="options.autoCrop"
							:auto-crop-width="options.autoCropWidth"
							:auto-crop-height="options.autoCropHeight"
							:center-box="options.centerBox"
							:high="options.high"
							@real-time="handleRealTime"
						/>
						<!-- <b-button variant="info"   size="sm" @click="crop()"> <i class="icon-crop"></i> </b-button>  -->
					</b-col>
					<b-col xl="3">
						<figure class="figure cam_capture-figure" :class="previews.div">
							<!--  <img :src="imgToDisplay" alt="no picture captured" style="width:300px;  border-radius: 15px; border: 1px solid #aaa; padding: 1px;" /> height="270"  -->
							<img :src="previews.url" alt="no picture cropped yet" />
							<figcaption class="figure-caption text-center">
								{{ FormMSG(1, 'Cropped picture') }}
							</figcaption>
						</figure>
						<div>
							<!-- <b-button variant="outline-secondary mb-2" block size="md" @click="handleResetCrop"> -->
							<!-- 	<i class="icon-action-undo" /> {{ FormMSG(9798, 'Reset crop') }} -->
							<!-- </b-button> -->
							<b-button variant="outline-secondary" block size="md" @click="rotate('left')">
								<i class="icon-reload" /> {{ FormMSG(4, 'Rotate picture') }}
							</b-button>
							<b-button
								v-if="hasValidationButton"
								style="width: 100%"
								:disabled="$nil(previews) || previews.url === ''"
								@click="handleConfirmCrop"
							>
								{{ FormMSG(2, 'Confirme Crop') }}
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<loading is-full-page :active.sync="isLoading" />
		</div>
	</b-modal>
</template>

<script>
// import Vue from 'vue';
// import VueCroppie from 'vue-croppie';
import Loading from 'vue-loading-overlay';
import { store } from '@/store';
import { isNil, isBase64, omit } from '@/shared/utils';
import { mergeIfObj } from '@/shared/helpers';

import languageMessages from '@/mixins/languageMessages';
import modalMixin from '@/mixins/modal.mixin';

// import 'croppie/croppie.css'; // import the croppie css manually

// Vue.use(VueCroppie);

export default {
	name: 'CropPicture',
	components: { Loading },
	mixins: [languageMessages, modalMixin],
	props: {
		hasValidationButton: {
			type: Boolean,
			required: false,
			default: false
		},
		boundary: {
			type: Object,
			required: false,
			default: () => ({ width: 420, height: 240 })
		},
		baseUrlImg: {
			type: Object,
			required: true,
			default: () => ({ img: null, index: 0, type: 'cropReplacement' })
		},
		type: {
			type: String,
			required: false,
			default: 'default',
			validator: (v) => ['default', 'avatar'].includes(v)
		}
	},
	data() {
		return {
			// cropped: '',
			// imgUrl: '',
			// firstTime: true,
			isLoading: false,
			refCrop: 0,
			imgListIndex: null,
			currCropType: 'cropReplacement',
			// cropOption: {},

			options: {
				img: '', // The address of the cropped image
				info: false, // The size of the crop box
				outputSize: 1, // The quality of the cropped image
				outputType: 'png', // The format of the cropped image jpeg, png, webp
				canScale: true, // Whether the image allows scroll wheel zoom
				fixedBox: false, // The size of the screenshot box is fixed and cannot be changed
				fixed: false, // Whether to open the fixed ratio of the width and height of the screenshot frame
				fixedNumber: [1, 1], // The width and height ratio of the screenshot frame
				autoCrop: true, // Whether to generate a screenshot box by default
				autoCropWidth: 180, // The width of the screenshot frame is generated by default, only the width and height of the automatic screenshot are enabled
				autoCropHeight: 180, // The height of the screenshot frame is generated by default, only the width and height of the automatic screenshot are enabled.
				full: true, // Whether to output a screenshot of the original image scale
				canMoveBox: true, // Can the screenshot box be dragged
				original: false, // The uploaded image is rendered at the original scale
				centerBox: true, // Whether the screenshot box is limited to the picture
				infoTrue: true, // true is to show the width and height of the real output picture false to show the width and height of the screenshot frame you see
				high: true,
				size: 1
			},
			previews: {} // Cut real-time preview data
		};
	},
	watch: {
		boundary: {
			/**
			 * @param {Object} opt
			 */
			handler(opt) {
				// console.log({ opt })
				/**
				 * @type {Object} _o
				 * @property {Number|String} autoCropWidth
				 * @property {Number|String} autoCropHeight
				 * @property {Array<Number>} fixedNumber
				 */
				const _o = {
					autoCropWidth: opt.width || 200,
					autoCropHeight: opt.height || 200,
					fixedNumber: isNil(opt) ? [] : [opt.width, opt.height],
					...omit(['width', 'height'], opt)
				};
				// console.log({ _o })
				this.options = Object.assign(this.options, _o);
			},
			immediate: true,
			deep: true
		},

		baseUrlImg: {
			handler(val) {
				this.initCropImgList(val);
			},
			deep: true
		},
		// 'previews.url'(img) {
		// 	if (isNil(img) || img === '') return;
		// 	this.$emit('cropped', img);
		// },
		isModalOpen: {
			handler(status) {
				// console.log({ status })
				if (!status) return;
				this.initCropImgList(this.baseUrlImg);
			},
			immediate: true
		}
	},
	methods: {
		// fillPreviewImgSrc(img) {
		//   const src = img === '' ? this.baseUrlImg.img : img
		//   this.$refs.croppieRef.bind({
		//     url: this.rendSrc(src)
		//   });
		// },

		initCropImgList(crop) {
			// console.log({ crop })
			if (isNil(crop)) return;
			const { img, index, type } = crop;
			// this.imgUrl = img;
			this.options.img = img;
			this.imgListIndex = index || 0;
			this.setCurrCropType(type);
			this.refCrop = this.refCrop++;
		},
		// bind() {
		//     console.log('func__bind')
		// 	this.imgUrl = store.state.imageToCrop;
		// 	this.$refs.croppieRef.bind({
		// 		url: this.rendSrc(this.imgUrl)
		// 	});
		// },
		/**
		 * @param {?String} [type=nul]
		 */
		setCurrCropType(type = null) {
			const _t = isNil(type) || !['cropReplacement', 'cropBeforeUpload'].includes(type) ? 'cropReplacement' : type;
			this.currCropType = type;
		},

		/**
		 * @param {String} img
		 * @return {String}
		 */
		rendSrc(img) {
			if (isNil(img)) return;
			if (isBase64(img)) return img;
			const path = process.env.VUE_APP_PATH_IMG_URL;
			return path + img;
		},

		// CALBACK USAGE
		// crop() {
		//     caonsole.log('func__crop')
		// 	// Here we are getting the result via callback function
		// 	// and set the result to this.cropped which is being
		// 	// used to display the result above.
		// 	let options = {
		// 		format: 'jpeg'
		// 		//  circle: true
		// 	};
		// 	this.$refs.croppieRef.result(options, (output) => {
		// 		this.cropped = output;
		// 		store.state.imageCropped = output;
		// 	});
		// },
		// EVENT USAGE
		// cropViaEvent() {
		//     console.log('func__cropViaEvent')
		// 	this.$refs.croppieRef.result(options);
		// },
		// result(output) {
		// 	this.cropped = output;
		// },
		/**
		 * rotating image in the cropper-container
		 * @param {String} [rotationAngle='left'] - 'left' or 'right'
		 */
		rotate(rotationAngle = 'left') {
			if (isNil(rotationAngle) || !['left', 'right'].includes(rotationAngle)) return;
			/** @type {Function} func */
			const func = rotationAngle === 'left' ? 'rotateLeft' : 'rotateRight';
			if (isNil(this.$refs.croppieRef[func])) return;
			this.$refs.croppieRef[func](rotationAngle);
		},
		// update() {
		// 	if (this.imgUrl != store.state.imageToCrop) {
		// 		this.bind();
		// 	}
		// 	if (this.firstTime) {
		// 		this.firstTime = false;
		// 		this.bind();
		// 	}
		// 	this.crop();
		// },
		/**
		 * Real-time preview function
		 *
		 * @param {Object} realTimeData
		 */
		handleRealTime(realTimeData) {
			// console.log({ realTimeData })
			this.$refs.croppieRef.getCropData((data) => {
				this.previews = realTimeData;
				this.previews.url = data;
			});
		},

		handleConfirmCrop() {
			this.$refs.croppieRef.getCropData((data) => {
				// console.log({ CONFIRME_CROP: data })
				this.$emit('change', {
					isCropEnded: true,
					img: data,
					index: this.imgListIndex,
					type: this.currCropType
				});
				// this.cropped = null;
			});
		},

		// handleResetCrop() {
		//   this.$refs.croppieRef.clearCrop((data) => {
		//     console.log({ RESET: data })
		//   })
		// },

		handleSaveCrop() {
			this.$refs.croppieRef.getCropData((data) => {
				/** @type {Boolean} _c */
				const _c = this.currCropType === 'cropReplacement';
				/** @type {String} emitter */
				const emitter = _c ? 'change' : 'cropped';
				/** @type {Object} emitPayload */
				const emitPayload = {
					img: data,
					index: this.imgListIndex,
					...mergeIfObj(_c, {
						isCropEnded: true,
						type: 'cropReplacement'
					})
				};

				this.$emit(emitter, emitPayload);
			});
		},
		handleCloseModal() {
			this.previews.url = '';
			this.imgListIndex = null;
			this.currCropType = 'cropReplacement';
			// this.cropOption = {};
			this.$emit('close', true);
		}
	}
};
</script>
